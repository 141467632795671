import React, { useMemo } from 'react';

function AddProductButton({ updateProduct, buttonText }) {
  const product = useMemo(
    () =>
      JSON.parse(document.querySelector('#add-product-button').dataset.product),
    []
  );

  const handleClick = () => {
    const productColor = JSON.parse(
      document.querySelector('#add-product-button').dataset.productColor
    );
    updateProduct(product, productColor);
  };

  return (
    <button onClick={handleClick} className="button button--alt button--full">
      <span className="plus-button--inline teal mrs heading-medium">+</span>
      {buttonText}
    </button>
  );
}

export default AddProductButton;
