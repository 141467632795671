import { createElement } from 'react';
import { render } from 'react-dom';
import SignupModal from './components/SignupModal';

const cookieString = 'USER_HAS_VISITED=true';

const setUserHasVisited = () => {
  document.cookie = cookieString;
};

const hasUserVisited = () => document.cookie.indexOf(cookieString) !== -1;

const renderSignupModal = () => {
  const modalElement = document.getElementById('modal');
  if (modalElement) {
    render(createElement(SignupModal), modalElement);
  }
};

export default () => {
  if (!hasUserVisited()) {
    setTimeout(() => {
      renderSignupModal();
      setUserHasVisited();
    }, 7000);
  }
};
