import React, { useState, Fragment } from 'react';
import { isServerSideRendering } from '../utility';

export default function ProductTable({ measurements, sizeChart }) {
  const isSSR = isServerSideRendering();

  const [showTable, setShowTable] = useState(isSSR);
  const showButton = !isSSR;

  return (
    <Fragment>
      {showButton && (
        <p className="mbn mtn">
          <button
            className="button-pseudo--teal heading-heavy"
            onClick={() => setShowTable(p => !p)}
          >
            {showTable ? 'Hide size chart -' : 'Show size chart +'}
          </button>
        </p>
      )}

      {showTable && (
        <table className="product-table">
          <thead>
            <tr>
              <th className="left">(In Inches)</th>
              {measurements.map((measurement, key) => (
                <th className="centered" key={key}>
                  <strong>{measurement}</strong>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sizeChart.map((sizeChartRow, k) => (
              <tr key={k}>
                <td className="left">
                  <strong>{sizeChartRow.size}</strong>
                </td>
                {sizeChartRow.measurements.map((measurement, key) => (
                  <td className="centered" key={key}>
                    {measurement}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Fragment>
  );
}
