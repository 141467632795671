import React, { useState } from 'react';

export default function CampusManagerLookUp({ value, schools }) {
  const [activeFilter, setActiveFilter] = useState(null);

  const filteredSchools = schools.filter(
    row => null || row.campus === activeFilter
  );

  const instagramUrl = 'https://www.instagram.com/';

  const handleChange = e => {
    const name = e.target;
    setActiveFilter(name.value || null);
  };

  return (
    <div className="stack">
      <select
        name="school"
        id="school"
        className="field"
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleChange}
      >
        <option value="">Select a campus</option>
        {schools
          .sort((a, b) => a.campus.localeCompare(b.campus))
          .map(school => (
            <option key={school.campus} value={school.campus}>
              {school.campus}
            </option>
          ))}
      </select>

      {filteredSchools.length === 0 ? (
        <p className="medium">
          Select a campus above to get in touch with your Campus Manager™!
        </p>
      ) : (
        <>
          {filteredSchools.map(item => (
            <div key={item.campus} className="stack stack--small">
              <div className="section-divided stack stack--x-small">
                <h3>{item.campus}</h3>
                <a
                  href={`${instagramUrl}${item.instagram}`}
                  className="row row-centered row-centered2 gap-xs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-instagram"></i>
                  <span>{item.instagram}</span>
                </a>
              </div>
              <ul className="list-unstyled mvn stack stack--x-small">
                {Object.values(item.details).map((cm, i) => (
                  <li key={i}>{cm.name}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
