import React, { useRef, useEffect } from 'react';
import ModalPageOne from './ModalPageOne';
import ModalPageTwo from './ModalPageTwo';
import ModalPageThree from './ModalPageThree';

function Modal(props) {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.closeModal();
      }
    };

    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        props.closeModal();
      }
    };

    if (props.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, props.isOpen, props.closeModal]);

  function scrollToTop() {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }

  useEffect(scrollToTop, [props.modalStepNumber]);
  if (!props.isOpen) return null;

  function handleBack() {
    props.setModalStepNumber(Math.max(props.modalStepNumber - 1, 0));
  }

  return (
    <div className="wizard-modal-wrapper">
      <div className="wizard-modal" ref={modalRef}>
        <button
          className="wizard-close-button"
          type="button"
          onClick={props.closeModal}
        >
          <span className="is-hidden-visually">Close</span>
        </button>
        {props.modalStepNumber === 1 && (
          <ModalPageOne
            modalStepNumber={props.modalStepNumber}
            setModalStepNumber={props.setModalStepNumber}
            formData={props.formData}
            handleChange={props.handleChange}
            handleRemoveDesign={props.handleRemoveDesign}
            handleRemoveProduct={props.handleRemoveProduct}
            designGalleryUrl={props.designGalleryUrl}
            productGalleryUrl={props.productGalleryUrl}
            handleStartOver={props.handleStartOver}
          />
        )}
        {props.modalStepNumber === 2 && (
          <ModalPageTwo
            modalStepNumber={props.modalStepNumber}
            setModalStepNumber={props.setModalStepNumber}
            handleBack={handleBack}
            schools={props.schools}
            handleChange={props.handleChange}
            handleSubmitQuoteRequest={props.handleSubmitQuoteRequest}
            formData={props.formData}
            recaptchaKey={props.recaptchaKey}
          />
        )}
        {props.modalStepNumber === 3 && (
          <ModalPageThree formData={props.formData} />
        )}
      </div>
    </div>
  );
}

export default Modal;
