import 'whatwg-fetch';
import { getAuthenticityToken } from './utility';

// https://github.com/github/fetch#handling-http-error-statuses
const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

const parseJSON = response => {
  return response.json();
};

export const get = url =>
  fetch(url, {
    credentials: 'same-origin',
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(() => {
      return Promise.reject('Error');
    });

export const post = (url, payload) =>
  fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getAuthenticityToken(),
    },
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(() => {
      // console.log('request failed', error);
      return Promise.reject('Error');
    });
