import React from 'react';
import { contrast, hexToRgb } from '../color';
import trim from 'lodash.trim';

export default function ColorCircle({ color, style, children, ...remaining }) {
  const strokeThreshold = 1.25;
  const backgroundColor = '#ffffff';
  const black = { r: 0, g: 0, b: 0 };
  const backgroundRgb = hexToRgb(trim(backgroundColor)) || black;
  const colorRba = hexToRgb(trim(color)) || black;
  const colorContrast = contrast(backgroundRgb, colorRba);
  const shouldStroke = colorContrast <= strokeThreshold;

  return (
    <div
      className="chip"
      style={{
        ...style,
        backgroundColor: `#${color}`,
        border: shouldStroke ? '1px solid rgba(0,0,0,0.25)' : 'none',
      }}
      {...remaining}
    >
      {children}
    </div>
  );
}
