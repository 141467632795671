import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactCountUp from 'react-countup';

const CountUp = props => {
  const [startCounting, setStartCounting] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !startCounting) {
      setStartCounting(true);
    }
  }, [inView, startCounting]);

  return <div ref={ref}>{startCounting && <ReactCountUp {...props} />}</div>;
};

export default CountUp;
