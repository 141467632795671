export default action => {
  const grecaptcha = window.grecaptcha;
  const key = window.googleRecaptchaSiteKey;

  const grecaptchaReady = new Promise((resolve, reject) =>
    grecaptcha.ready(() => resolve())
  );

  return grecaptchaReady.then(() =>
    grecaptcha.execute(key, { action }).then(token => ({
      'g-recaptcha-response': token,
    }))
  );
};
