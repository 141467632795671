import React, { useState, useEffect, useRef, useCallback } from 'react';

export default function LightboxGallery({ images, gridClass }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const modalEl = useRef();

  const selectImage = index => {
    setCurrentImageIndex(index);
  };

  const dismissModal = () => setCurrentImageIndex(null);

  // Show previous image
  const prevImage = useCallback(() => {
    if (currentImageIndex === null) return;
    let newIndex = currentImageIndex - 1;
    if (currentImageIndex === 0) {
      newIndex = images.length - 1;
    }
    setCurrentImageIndex(newIndex);
  }, [currentImageIndex, images.length]);

  // Show next image
  const nextImage = useCallback(() => {
    if (currentImageIndex === null) return;
    const newIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(newIndex);
  }, [currentImageIndex, images.length]);

  const image = currentImageIndex !== null ? images[currentImageIndex] : null;

  // Dismiss modal by clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalEl.current === event.target) {
        dismissModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Dismiss modal by hitting escape
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Escape') dismissModal();
      else if (e.key === 'ArrowRight') nextImage();
      else if (e.key === 'ArrowLeft') prevImage();
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [nextImage, prevImage]);

  return (
    <>
      <div className={gridClass}>
        {images.map((img, index) => {
          return (
            <button
              key={index}
              onClick={() => selectImage(index)}
              className="button-clean"
            >
              <img
                src={img.src}
                alt={img.alt}
                className="block u-border-radius-sm"
              />
            </button>
          );
        })}
      </div>
      {image && (
        <div ref={modalEl} className="lightbox-modal">
          <button
            className="lightbox-modal__close-icon close-icon-light button-clean"
            onClick={() => dismissModal()}
          >
            <span className="is-hidden-visually">Close</span>
          </button>
          <div className="lightbox-modal__inner">
            <div>
              <button className="arrow-prev" onClick={() => prevImage()}>
                <span className="is-hidden-visually">Previous</span>
              </button>
            </div>
            <div>
              <figure>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="u-border-radius-md"
                />
                {image.alt && (
                  <figcaption>
                    <p>{image.alt}</p>
                  </figcaption>
                )}
              </figure>
            </div>
            <div>
              <button className="arrow-next" onClick={() => nextImage()}>
                <span className="is-hidden-visually">Next</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
