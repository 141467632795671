import throttle from 'lodash/throttle';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import clamp from 'lodash/clamp';

const onScreenHeight = section => {
  const viewportHeight = window.innerHeight;
  const sectionRect = section.getBoundingClientRect();
  const viewportTop = window.scrollY;
  const viewportBottom = window.scrollY + viewportHeight;
  const sectionTop = sectionRect.top + viewportTop;
  const sectionBottom = sectionRect.bottom + viewportTop;
  const clampedTop = clamp(sectionTop, viewportTop, viewportBottom);
  const clampedBottom = clamp(sectionBottom, viewportTop, viewportBottom);
  return clampedBottom - clampedTop;
};

const activeSection = sections => last(sortBy(sections, [onScreenHeight]));

const dotForSection = section =>
  document.querySelector(`[href="#${section.id}"]`);

const ScrollNavigation = () => {
  const pageSections = document.querySelectorAll('.page-section');

  function checkSlide() {
    pageSections.forEach(s => {
      const dot = dotForSection(s);
      if (dot) dot.classList.remove('active');
    });

    const section = activeSection(pageSections);

    if (section) {
      const dot = dotForSection(section);
      if (dot) dot.classList.add('active');
    }
  }

  checkSlide();
  window.addEventListener('scroll', throttle(checkSlide, 50));
};

export default ScrollNavigation;
