const initBasementNav = () => {
  let selector = ['.js-menu-trigger', '.js-menu-screen'].join(',');

  let mobileSelector = ['.js-menu', '.js-menu-screen'].join(',');

  let clickables = document.querySelectorAll(selector);
  let mobileElements = document.querySelectorAll(mobileSelector);
  let wrap = document.querySelector('.site-wrap');

  for (let i = 0; i < clickables.length; ++i) {
    clickables[i].addEventListener('click', () => {
      wrap.classList.toggle('site-wrap--open');
      for (let j = 0; j < mobileElements.length; ++j) {
        mobileElements[j].classList.toggle('is-visible');
      }
    });
  }
};

const initAutoSubmit = () => {
  let selector = '[data-auto-submit]';
  let autoSelects = document.querySelectorAll(selector);

  for (let i = 0; i < autoSelects.length; ++i) {
    autoSelects[i].addEventListener('keypress', function(e) {
      if (e.which === 10 || e.which === 13) {
        this.form.submit();
      }
    });
  }
};

const showHiddenField = () => {
  const cmForm = document.querySelector('#form-answers');
  const phoneField = document.getElementById('hidden-phone');
  const preferredContact = document.querySelector(
    'input[name="campus_managers_form[preferred_contact]"]'
  );
  if (preferredContact) {
    let previousValue = `${preferredContact}:checked`.value;
    cmForm.onclick = function() {
      const newValue = document.querySelector(
        'input[name="campus_managers_form[preferred_contact]"]:checked'
      ).value;
      if (newValue === previousValue) return;
      if (newValue === 'Email') {
        phoneField.classList.remove('show-phone');
        phoneField.classList.add('hide-phone');
      } else {
        phoneField.classList.add('show-phone');
        phoneField.classList.remove('hide-phone');
        document.getElementById('phone-label').classList.add('required-marker');
      }
      if (newValue === 'Whatever') {
        document
          .getElementById('phone-label')
          .classList.remove('required-marker');
      }
      previousValue = newValue;
    };
  }
};

const addJSTagToBody = () => {
  let body = document.querySelector('body');
  body.classList.toggle('js');
};

const addZendeskButton = () => {
  if (window.zE) {
    let buttons = document.querySelectorAll('[data-zendesk-button]');

    window.zE(() => {
      window.zE.hide();
    });

    for (let i = 0; i < buttons.length; i++) {
      let button = buttons[i];
      let updateChatButtonText = status => {
        let text = status === 'online' ? 'Chat Now' : 'Leave a Message';
        button.textContent = text;
        button.classList.remove('button--disabled');
      };

      let zopimListener = window.setInterval(() => {
        if (
          window.$zopim !== undefined &&
          window.$zopim.livechat !== undefined
        ) {
          clearInterval(zopimListener);
          window.$zopim.livechat.setOnStatus(updateChatButtonText);
        }
      }, 10);

      button.addEventListener('click', e => {
        e.preventDefault();
        window.zE.activate({ hideOnClose: true });
      });
    }
  }
};

const initDesktopSubNav = () => {
  const navTriggers = document.querySelectorAll('[data-sub-nav]');

  navTriggers.forEach(trigger => {
    const subNavId = trigger.getAttribute('data-sub-nav');
    const subNav = document.getElementById(subNavId);

    const onEnter = e => {
      e.preventDefault();
      trigger.classList.toggle('active', true);
    };

    const onLeave = e => {
      e.preventDefault();
      trigger.classList.toggle('active', false);
    };

    trigger.addEventListener('mouseenter', onEnter);
    trigger.addEventListener('mouseleave', onLeave);
    subNav.addEventListener('mouseenter', onEnter);
    subNav.addEventListener('mouseleave', onLeave);
  });
};

const initFilterSlider = () => {
  let filterSelector = ['.js-filter-trigger', '.js-filter-menu-screen'].join(
    ','
  );

  let filterMobileSelector = ['.js-filter-menu', '.js-filter-menu-screen'].join(
    ','
  );

  let filterClickables = document.querySelectorAll(filterSelector);
  let filterMobileElements = document.querySelectorAll(filterMobileSelector);

  filterClickables.forEach(filter => {
    filter.addEventListener('click', () => {
      filterMobileElements.forEach(mobileFilter => {
        mobileFilter.classList.toggle('is-visible');
      });
    });
  });
};

// This determines if a user is a keyboard user. If so, we apply specific focus styles:
// https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');

    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('user-is-tabbing');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

function initFocus() {
  window.addEventListener('keydown', handleFirstTab);
}

let init = () => {
  initBasementNav();
  initAutoSubmit();
  addJSTagToBody();
  showHiddenField();
  addZendeskButton();
  initDesktopSubNav();
  initFilterSlider();
  initFocus();
};

export default init;
