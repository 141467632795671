import React, { useState, useEffect } from 'react';
import NukaCarousel from 'nuka-carousel';
import { viewPortWidth } from '../utility';
import { hydrateReactElements } from '../utility';

function HeroCarousel({ slides, options }) {
  const [isWide, setIsWide] = useState(false);
  const [isMedium, setIsMedium] = useState(false);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      updateSize();
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function updateSize() {
    setIsWide(viewPortWidth() > 900);
    setIsMedium(viewPortWidth() > 700);
  }

  slides = JSON.parse(slides);

  let imgs = hydrateReactElements(slides);

  const sizeProp = isWide
    ? {
        slidesToShow: options.wideSlides,
        autoplay: options.wideAutoplay,
        wrapAround: options.wideWrapAround,
      }
    : isMedium
    ? {
        slidesToShow: options.mediumSlides,
        autoplay: options.mediumAutoplay,
        wrapAround: options.mediumWrapAround,
      }
    : {
        slidesToShow: options.smallSlides,
        autoplay: options.smallAutoplay,
        wrapAround: options.smallWrapAround,
      };

  let nukaCarouselProps = {
    withoutControls: true,
    autoplayInterval: 3000,
    speed: 300,
    easing: 'easeQuadInOut',
    cellSpacing: 0,
    ...sizeProp,
  };

  return <NukaCarousel {...nukaCarouselProps}>{imgs}</NukaCarousel>;
}

export default HeroCarousel;
