import React, { useState, useEffect } from 'react';
import NukaCarousel from 'nuka-carousel';
import { viewPortWidth } from '../utility';
import { hydrateReactElements } from '../utility';
import IconPrev from '../images/icons/icon-arrow-prev-circle.svg';
import IconNext from '../images/icons/icon-arrow-next-circle.svg';

function BasicCarousel({ slides, options }) {
  const [isWide, setIsWide] = useState(false);
  const [isMedium, setIsMedium] = useState(false);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      updateSize();
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function updateSize() {
    setIsWide(viewPortWidth() > 900);
    setIsMedium(viewPortWidth() > 700);
  }

  slides = JSON.parse(slides);

  let imgs = hydrateReactElements(slides);

  const sizeProp = isWide
    ? {
        slidesToShow: options.wideSlides,
        autoplay: options.wideAutoplay,
        wrapAround: options.wideWrapAround,
      }
    : isMedium
    ? {
        slidesToShow: options.mediumSlides,
        autoplay: options.mediumAutoplay,
        wrapAround: options.mediumWrapAround,
      }
    : {
        slidesToShow: options.smallSlides,
        autoplay: options.smallAutoplay,
        wrapAround: options.smallWrapAround,
      };

  let nukaCarouselProps = {
    withoutControls: options.withoutControls,
    autoplayInterval: options.autoplayInterval,
    speed: options.speed,
    easing: 'easeQuadInOut',
    cellSpacing: 0,
    cellAlign: options.cellAlign,
    ...sizeProp,
  };

  return (
    <NukaCarousel
      {...nukaCarouselProps}
      renderCenterLeftControls={({ previousSlide }) => (
        <button className="button-clean mlm" onClick={() => previousSlide()}>
          <img src={IconPrev} alt="" height="32" width="32" />
          <span className="is-hidden-visually">Previous</span>
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button className="button-clean mrm" onClick={() => nextSlide()}>
          <img src={IconNext} alt="" height="32" width="32" />
          <span className="is-hidden-visually">Next</span>
        </button>
      )}
      renderBottomCenterControls={null}
    >
      {imgs}
    </NukaCarousel>
  );
}

export default BasicCarousel;
