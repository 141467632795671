import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PlayIcon from '../images/icons/icon-play.svg';
import StopIcon from '../images/icons/icon-stop.svg';

export default function InlineVideo({ videoUrl, imageSrc, altText }) {
  const [isOpen, setIsOpen] = useState(false);

  const videoRef = useRef(null);

  const handleClick = () => {
    setIsOpen(v => !v);
  };

  // Dismiss video by hitting escape
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Escape') setIsOpen(false);
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  // Start playing video when opened
  useEffect(() => {
    if (isOpen && videoRef.current) {
      videoRef.current.play();
    }
  }, [isOpen]);

  const buttonIcon = isOpen ? StopIcon : PlayIcon;
  const buttonText = isOpen ? 'Tap to Close' : 'Tap to Play';

  const imageClasses = classNames({
    'testimonial-card__media-image': true,
    'testimonial-card__media-image--filter': isOpen,
  });

  return (
    <>
      <button
        type="button"
        className="testimonial-card__button"
        onClick={handleClick}
      >
        <div className="row row-centered row-pushed ptm phm">
          <img src={buttonIcon} alt="" height="36" width="36" />
          <span className="strong white">{buttonText}</span>
        </div>
      </button>
      <div className="contain-content">
        <img
          src={imageSrc}
          className={imageClasses}
          alt={altText}
          width="600"
          height="600"
        />
      </div>
      {isOpen && (
        <>
          {/* eslint-disable jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            className="testimonial-card__media-video"
            playsInline
          >
            <source src={videoUrl} />
          </video>
          {/* eslint-ensable jsx-a11y/media-has-caption */}
        </>
      )}
    </>
  );
}
