import React from 'react';
import NukaCarousel from 'nuka-carousel';
import slide1Image from '../images/lig/flatlay-01.png';
import slide2Image from '../images/lig/flatlay-02.png';
import slide3Image from '../images/lig/flatlay-03.png';
import slide4Image from '../images/lig/flatlay-04.png';

export default function ApparelCarousel() {
  let nukaCarouselProps = {
    slidesToShow: 1,
    autoplay: true,
    wrapAround: true,
    withoutControls: true,
    autoplayInterval: 4000,
    speed: 400,
    easing: 'easeQuadInOut',
    cellSpacing: 0,
  };

  return (
    <div className="paxl pam--m bg-light-blue">
      <div className="stack stack--sm centered mtl">
        <h2 className="heading-base heading-liberator-light c-heading-1">
          Approved Apparel Options
        </h2>
        <p className="mbl">
          <em>
            The following apparel styles and colors have been approved by Life
            is Good® for use with their designs.
          </em>
        </p>
      </div>
      <div className="capped-width-container">
        <NukaCarousel {...nukaCarouselProps}>
          <div className="row row-centered">
            <div className="col-6--m">
              <img src={slide1Image} width="543" height="543" alt="" />
            </div>
            <div className="col-6--m stack plxl pam--m">
              <h3>
                Comfort Colors Short Sleeve Tee
                <br />
                STYLE: 1717
              </h3>
              <div id="product-detail-chips" className="chips mbm">
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#ffffff' }}>
                    <div className="tooltip">
                      <p className="uppercase">White</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#f6f2dd' }}>
                    <div className="tooltip">
                      <p className="uppercase">Ivory</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#F5E098' }}>
                    <div className="tooltip">
                      <p className="uppercase">Butter</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E4C0CB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Blossom</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E1A694' }}>
                    <div className="tooltip">
                      <p className="uppercase">Terracota</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#973C3D' }}>
                    <div className="tooltip">
                      <p className="uppercase">Cumin</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4B4DB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Orchid</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4EDF2' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chambray</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#6489D4' }}>
                    <div className="tooltip">
                      <p className="uppercase">Flo Blue</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A3BCB1' }}>
                    <div className="tooltip">
                      <p className="uppercase">Bay</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#4EAFA8' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chalky Mint</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#60A784' }}>
                    <div className="tooltip">
                      <p className="uppercase">Light Green</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A4ACAB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Granite</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stack stack--small">
                <h4>Colors</h4>
                <p>
                  White, Ivory, Butter, Blossom, Terracota, Cumin, Orchid,
                  Chambray, Flo Blue, Bay, Chalky Mint, Light Green, Granite
                </p>
              </div>
              <div className="stack stack--small">
                <h4>Print Locations</h4>
                <p>
                  Full Front, Center Chest, Left Chest, Full Back, Upper Back
                </p>
              </div>
            </div>
          </div>

          <div className="row row-centered">
            <div className="col-6--m">
              <img src={slide2Image} width="543" height="543" alt="" />
            </div>
            <div className="col-6--m stack plxl pam--m">
              <h3>
                Comfort Colors Short Sleeve Tee
                <br />
                STYLE: 6014
              </h3>
              <div id="product-detail-chips" className="chips mbm">
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#ffffff' }}>
                    <div className="tooltip">
                      <p className="uppercase">White</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#f6f2dd' }}>
                    <div className="tooltip">
                      <p className="uppercase">Ivory</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#F5E098' }}>
                    <div className="tooltip">
                      <p className="uppercase">Butter</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E4C0CB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Blossom</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E1A694' }}>
                    <div className="tooltip">
                      <p className="uppercase">Terracota</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#973C3D' }}>
                    <div className="tooltip">
                      <p className="uppercase">Cumin</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4B4DB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Orchid</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4EDF2' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chambray</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#6489D4' }}>
                    <div className="tooltip">
                      <p className="uppercase">Flo Blue</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A3BCB1' }}>
                    <div className="tooltip">
                      <p className="uppercase">Bay</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#4EAFA8' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chalky Mint</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#60A784' }}>
                    <div className="tooltip">
                      <p className="uppercase">Light Green</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A4ACAB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Granite</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stack stack--small">
                <h4>Colors</h4>
                <p>
                  White, Ivory, Butter, Blossom, Terracota, Cumin, Orchid,
                  Chambray, Flo Blue, Bay, Chalky Mint, Light Green, Granite
                </p>
              </div>
              <div className="stack stack--small">
                <h4>Print Locations</h4>
                <p>
                  Full Front, Center Chest, Left Chest, Full Back, Upper Back
                </p>
              </div>
            </div>
          </div>

          <div className="row row-centered">
            <div className="col-6--m">
              <img src={slide3Image} width="543" height="543" alt="" />
            </div>
            <div className="col-6--m stack plxl pam--m">
              <h3>
                Comfort Colors Crewneck
                <br />
                STYLE: 1566
              </h3>
              <div id="product-detail-chips" className="chips mbm">
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#ffffff' }}>
                    <div className="tooltip">
                      <p className="uppercase">White</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#f6f2dd' }}>
                    <div className="tooltip">
                      <p className="uppercase">Ivory</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#F5E098' }}>
                    <div className="tooltip">
                      <p className="uppercase">Butter</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E4C0CB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Blossom</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E1A694' }}>
                    <div className="tooltip">
                      <p className="uppercase">Terracota</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#973C3D' }}>
                    <div className="tooltip">
                      <p className="uppercase">Cumin</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4B4DB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Orchid</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#C4EDF2' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chambray</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#6489D4' }}>
                    <div className="tooltip">
                      <p className="uppercase">Flo Blue</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A3BCB1' }}>
                    <div className="tooltip">
                      <p className="uppercase">Bay</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#4EAFA8' }}>
                    <div className="tooltip">
                      <p className="uppercase">Chalky Mint</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#60A784' }}>
                    <div className="tooltip">
                      <p className="uppercase">Light Green</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A4ACAB' }}>
                    <div className="tooltip">
                      <p className="uppercase">Granite</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stack stack--small">
                <h4>Colors</h4>
                <p>
                  White, Ivory, Butter, Blossom, Terracota, Cumin, Orchid,
                  Chambray, Flo Blue, Bay, Chalky Mint, Light Green, Granite
                </p>
              </div>
              <div className="stack stack--small">
                <h4>Print Locations</h4>
                <p>
                  Full Front, Center Chest, Left Chest, Full Back, Upper Back
                </p>
              </div>
            </div>
          </div>

          <div className="row row-centered">
            <div className="col-6--m">
              <img src={slide4Image} width="543" height="543" alt="" />
            </div>
            <div className="col-6--m stack plxl pam--m">
              <h3>
                Charles River Go-To Classic Hoodie
                <br />
                STYLE: 9235
              </h3>
              <div id="product-detail-chips" className="chips mbm">
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#ffffff' }}>
                    <div className="tooltip">
                      <p className="uppercase">White</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#E7E0CA' }}>
                    <div className="tooltip">
                      <p className="uppercase">Sand</p>
                    </div>
                  </div>
                </div>
                <div className="tooltip-item">
                  <div className="chip" style={{ backgroundColor: '#A5B6A0' }}>
                    <div className="tooltip">
                      <p className="uppercase">Sage</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stack stack--small">
                <h4>Colors</h4>
                <p>
                  White, Ivory, Butter, Blossom, Terracota, Cumin, Orchid,
                  Chambray, Flo Blue, Bay, Chalky Mint, Light Green, Granite
                </p>
              </div>
              <div className="stack stack--small">
                <h4>Print Locations</h4>
                <p>
                  Full Front, Center Chest, Left Chest, Full Back, Upper Back
                </p>
              </div>
            </div>
          </div>
        </NukaCarousel>
      </div>
    </div>
  );
}
