import React from 'react';

function ModalPageOne(props) {
  const handleNext = () => {
    props.setModalStepNumber(props.modalStepNumber + 1);
  };

  const designNotesUI = (
    <div className="mtn">
      <label className="input-label-thin mtl" htmlFor="designNotes">
        Design Notes
      </label>
      <textarea
        id="designNotes"
        value={props.formData.designNotes}
        className="field"
        onChange={e => {
          props.handleChange('designNotes', e.target.value);
        }}
        placeholder="Include design details, print locations, or special instructions here! Feel free to reference other designs and products."
      />
    </div>
  );

  const designUI = props.formData.design && (
    <div>
      <div className="relative">
        <a href={props.formData.design.designUrl}>
          <img
            src={props.formData.design.url}
            alt="Design Square"
            className="wizard-design wizard-design--modal"
          />
        </a>
        <button
          onClick={props.handleRemoveDesign}
          className="mtn button-pseudo wizard-clear-button"
        >
          <i className="icon-x-close"></i>
        </button>
      </div>
      {props.formData.design.artCode && (
        <>
          <p className="mvn">
            <strong className="mvn">Art Code</strong>
          </p>
          <p className="mtn">
            <strong>{props.formData.design.artCode}</strong>
          </p>
        </>
      )}
      <a
        className="button button--alt button--full-res"
        href={props.designGalleryUrl}
      >
        Update Design
      </a>
    </div>
  );

  const designPlaceholderUI = (
    <div className="row">
      <a className="plus-button centered" href={props.designGalleryUrl}>
        Add Design
      </a>
    </div>
  );

  const productUI = props.formData.product && (
    <div>
      <div className="relative">
        <a href={props.formData.product.productUrl}>
          <img
            src={props.formData.productColor.marketingImageUrl}
            alt={props.formData.productColor.name}
            className="wizard-product wizard-product--modal"
          />
        </a>
        <button
          onClick={props.handleRemoveProduct}
          className="mtn button-pseudo wizard-clear-button"
        >
          <i className="icon-x-close"></i>
        </button>
      </div>
      <p className="mvn">
        <strong>
          {props.formData.product.brandName} {props.formData.product.style}{' '}
        </strong>
      </p>
      {props.formData.productColor.name && (
        <p className="mvn">
          <strong>{props.formData.productColor.name}</strong>
        </p>
      )}
      <a
        className="button button--alt button--full-res mtm"
        href={props.productGalleryUrl}
        onClick={props.closeModal}
      >
        Update Product
      </a>
    </div>
  );

  const productPlaceholderUI = (
    <div className="row">
      <a className="plus-button centered" href={props.productGalleryUrl}>
        Add Product
      </a>
    </div>
  );

  return (
    <div>
      <p className="uppercase centered mtn">1 of 2</p>
      <h2 className="heading-liberator-light c-heading c-heading-2 centered mvn">
        Start your order
      </h2>
      <p className="mts mbm centered small">
        All designs are fully customizable! Have a different idea for your
        order? Skip this step to complete your request.
      </p>
      <div className="wizard-image-grid">
        <div>{designUI || designPlaceholderUI}</div>
        <div>{productUI || productPlaceholderUI}</div>
      </div>
      {designNotesUI}
      <div className="row row-pushed">
        <div>
          {(props.formData.design || props.formData.product) && (
            <button
              className="button button--alt mtl"
              onClick={props.handleStartOver}
            >
              Start Over
            </button>
          )}
        </div>
        <div>
          <button
            className="button-pseudo small button-small mrs mtl"
            onClick={handleNext}
          >
            Skip This Step
          </button>
          <button className="button mtl" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalPageOne;
