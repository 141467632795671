import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function Modal({
  modalOpen = false,
  modalMaxHeight = '600px',
  modalMaxWidth = '800px',
  children,
}) {
  const [isOpen, setIsOpen] = useState(modalOpen);
  const modalEl = useRef();

  const onClose = e => {
    setIsOpen(false);
  };

  // Dismiss modal by clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalEl.current && !modalEl.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Dismiss modal by hitting escape
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  let dimensionProps = {
    style: {
      maxHeight: modalMaxHeight,
      maxWidth: modalMaxWidth,
    },
  };

  return (
    <>
      {isOpen && (
        <div ref={modalEl} className="modal" {...dimensionProps}>
          <button onClick={() => onClose()} className="close-icon" />
          {children}
        </div>
      )}
    </>
  );
}

Modal.propTypes = {
  modalOpen: PropTypes.bool,
  modalMaxHeight: PropTypes.string,
  modalMaxWidth: PropTypes.string,
  style: PropTypes.object,
};
