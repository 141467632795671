import React, { useMemo } from 'react';

function AddDesignButton(props) {
  const design = useMemo(
    () =>
      JSON.parse(document.querySelector('#add-design-button').dataset.design),
    []
  );

  const handleClick = () => {
    props.updateDesign(design);
  };

  return (
    <div className="row">
      <button onClick={handleClick} className="button button--alt button--full">
        <span className="plus-button--inline teal mrs heading-medium">+</span>
        {props.buttonText}
      </button>
    </div>
  );
}

export default AddDesignButton;
