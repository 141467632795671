import React from 'react';
import NukaCarousel from 'nuka-carousel';
import { hydrateReactElements } from '../utility';

function TitleCarousel({ slides }) {
  slides = JSON.parse(slides);

  let titles = hydrateReactElements(slides);

  let nukaCarouselProps = {
    withoutControls: true,
    autoplay: true,
    autoplayInterval: 1500,
    wrapAround: true,
    speed: 500,
    easing: 'easeQuadInOut',
    vertical: true,
    slidesToShow: 1,
    pauseOnHover: false,
    swiping: false,
    dragging: false,
  };

  return <NukaCarousel {...nukaCarouselProps}>{titles}</NukaCarousel>;
}

export default TitleCarousel;
