import React from 'react';

const Step4 = ({ value, onChange, onSubmit, backButton, toggleClick }) => {
  return (
    <section className="stack stack--large stretch phm" id="step-3">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 4 of 4</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Philanthropy Chair Info
        </h1>
      </div>
      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="stack stack--small">
          <label
            className="input-label"
            htmlFor="philanthropy-chair-first-name"
          >
            Philanthropy Chair First Name
          </label>
          <input
            className="field"
            type="text"
            id="philanthropy-chair-first-name"
            value={value.philanthropyChairFirstName}
            placeholder="Ex: Johnny"
            onChange={e => {
              onChange({
                ...value,
                philanthropyChairFirstName: e.target.value,
              });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="philanthropy-chair-last-name">
            Philanthropy Chair Last Name
          </label>
          <input
            className="field"
            type="text"
            id="philanthropy-chair-last-name"
            value={value.philanthropyChairLastName}
            placeholder="Ex: Appleseed"
            onChange={e => {
              onChange({ ...value, philanthropyChairLastName: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="philanthropy-chair-email">
            Philanthropy Chair Email
          </label>
          <input
            className="field"
            type="email"
            id="philanthropy-chair-email"
            placeholder="Ex: email@address.com"
            value={value.philanthropyChairEmail}
            onChange={e => {
              onChange({ ...value, philanthropyChairEmail: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="philanthropy-chair-phone">
            Philanthropy Chair Phone Number
          </label>
          <input
            className="field"
            type="tel"
            id="philanthropy-chair-phone"
            value={value.philanthropyChairPhone}
            placeholder="Ex: 555-555-5555"
            onChange={e => {
              onChange({ ...value, philanthropyChairPhone: e.target.value });
            }}
          />
        </div>

        <p className="x-small pts">
          By signing up your chapter, you agree to receive marketing text
          messages from University Tees at the number provided, including
          messages sent by autodialer. Consent is not a condition of any
          purchase. Message and data rates may apply. Message frequency varies.
          Reply HELP for help or STOP to cancel. View our{' '}
          <a href="/privacy_policy" target="_blank">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href="/terms_of_use" target="_blank">
            Terms of Use
          </a>
        </p>

        <div className="centered mtl">
          <button className="button">Submit Form Now</button>
        </div>
      </form>
    </section>
  );
};

export default Step4;
