import React, { useState, useEffect } from 'react';
import NukaCarousel from 'nuka-carousel';
import { viewPortWidth } from '../utility';
import { hydrateReactElements } from '../utility';

function FeatureCarousel({ slides }) {
  const [isWide, setIsWide] = useState(false);
  const [isMedium, setIsMedium] = useState(false);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      updateSize();
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function updateSize() {
    setIsWide(viewPortWidth() > 900);
    setIsMedium(viewPortWidth() > 500);
  }

  slides = JSON.parse(slides);

  let imgs = hydrateReactElements(slides);

  const sizeProp = isWide
    ? { slidesToShow: 3 }
    : isMedium
    ? { slidesToShow: 3 }
    : { slidesToShow: 1 };

  let nukaCarouselProps = {
    autoplay: true,
    autoplayInterval: 3000,
    wrapAround: true,
    speed: 500,
    easing: 'easeQuadInOut',
    cellSpacing: 30,
    ...sizeProp,
  };

  return (
    <NukaCarousel
      {...nukaCarouselProps}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomCenterControls={({ slideCount, currentSlide, goToSlide }) => (
        <div className="feature-slide-container">
          <ul className="feature-slide-list">
            {[...Array(slideCount)].map((_e, i) => (
              <li
                className={`feature-slide-item-${i + 1}${
                  currentSlide === i ? ' feature-slide-item--active' : ''
                }`}
                key={i}
              >
                <button
                  className="button-clean"
                  type="button"
                  aria-label={`slide ${i + 1} bullet`}
                  onClick={() => goToSlide(i)}
                >
                  <span className="is-hidden-visually">
                    {`Go to slide ${i + 1}`}
                  </span>
                  <div className="feature-slide-indicator" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    >
      {imgs}
    </NukaCarousel>
  );
}

export default FeatureCarousel;
