import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import AddDesignButton from './OrderWizard/AddDesignButton';
import AddProductButton from './OrderWizard/AddProductButton';
import Drawer from './OrderWizard/Drawer';
import Modal from './OrderWizard/Modal';
import { post } from '../store';
import snakecase from 'lodash.snakecase';
import reCAPTCHABase from '../reCAPTCHABase';

const storedFormData = () =>
  JSON.parse(localStorage.getItem('formData')) || {
    isStudent: true,
    school: 'Other',
  };

const openModalButton = ({ isNew, onClick }) => {
  return (
    <button
      className={`button wizard-open-button mlm ${
        isNew ? '' : 'wizard-open-button--animation'
      }`}
      type="button"
      onClick={onClick}
    >
      {isNew ? 'Start an Order' : 'Quote Request in Progress'}
    </button>
  );
};

const OrderWizard = props => {
  const addDesignDomNode = useMemo(
    () => document.querySelector('#add-design-button'),
    []
  );
  const addProductDomNode = useMemo(
    () => document.querySelector('#add-product-button'),
    []
  );

  const desktopWizardButtonDomNode = useMemo(
    () => document.getElementById('desktop-wizard-button'),
    []
  );

  const mobileWizardButtonDomNode = useMemo(
    () => document.getElementById('mobile-wizard-button'),
    []
  );

  const quotePageButtonDomNode = useMemo(
    () => document.getElementById('quote-page-button'),
    []
  );

  const [formData, setFormData] = useState(storedFormData);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStepNumber, setModalStepNumber] = useState(1);

  const designRequestInProgress = formData.design || formData.product;

  const clearLocalStorage = () => {
    setFormData({ isStudent: formData.isStudent, school: 'Other' });
  };

  function handleStartOver() {
    const userConfirmed = window.confirm(
      'Are you sure you want to start over? This will clear all of your selections and inputted data.'
    );
    if (userConfirmed === true) {
      clearLocalStorage();
    }
  }

  function handleRemoveDesign() {
    if (
      window.confirm(
        'Are you sure you want to remove your design? This will remove your design from your quote request.'
      ) === true
    ) {
      handleChange('design', null);
    }
  }

  function handleRemoveProduct() {
    if (
      window.confirm(
        'Are you sure you want to remove your product? This will remove your product from your quote request.'
      ) === true
    ) {
      handleChange('product', null);
    }
  }

  function handleChange(name, value) {
    setFormData({ ...formData, [name]: value });
  }

  function updateFormDataAttributes(attributes) {
    setFormData({ ...formData, ...attributes });
  }

  function updateDesign(design) {
    handleChange('design', {
      id: design.id,
      url: design.url,
      artCode: design.artCode,
      designUrl: design.designUrl,
    });
    setModalIsOpen(true);
  }

  function updateProduct(product, productColor) {
    updateFormDataAttributes({
      product: {
        id: product.id,
        description: product.description,
        name: product.name,
        style: product.style,
        brandName: product.brandName,
        productUrl: product.productUrl,
      },
      productColor: {
        id: productColor.id,
        name: productColor.name,
        marketingImageUrl: productColor.marketingImageUrl,
        hexCode: productColor.hexCode,
      },
    });

    setModalIsOpen(true);
  }

  const snakeCaseKeys = obj => {
    if (Array.isArray(obj)) {
      return obj.map(snakeCaseKeys);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeKey = snakecase(key);
        acc[snakeKey] = snakeCaseKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  const handleSubmitQuoteRequest = () => {
    const quote_form = snakeCaseKeys(formData);

    const payload = { quote_form: quote_form };
    reCAPTCHABase('quote_form').then(p =>
      post('/quote', { ...payload, ...p })
        .then(response => {
          console.log('Quote request submitted successfully:', response);
          clearLocalStorage();
          setModalStepNumber(3);
        })
        .catch(error => {
          console.error('Error submitting quote request:', error);
        })
    );
  };

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  function handleOpenModal() {
    setModalIsOpen(true);
    document.body.classList.add('wizard-no-scroll');
  }

  function closeModal() {
    setModalIsOpen(false);
    if (modalStepNumber === 3) {
      setModalStepNumber(1);
    }
    document.body.classList.remove('wizard-no-scroll');
  }

  const addProductButtonText =
    formData && formData.product
      ? 'Update your product on your quote request'
      : formData.design
      ? 'Add this product to your quote request'
      : 'Start an order with this product';

  const addDesignButtonText = formData.design
    ? 'Update your design on your quote request'
    : formData.product
    ? 'Add this design to your quote request'
    : 'Start an order with this design';

  return (
    <>
      <div
        id="wizard-bg-overlay"
        className={`wizard-bg-overlay ${
          modalIsOpen ? 'wizard-bg-overlay--active' : ''
        }`}
      ></div>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          productGalleryUrl={props.productGalleryUrl}
          designGalleryUrl={props.designGalleryUrl}
          schools={props.schools}
          handleStartOver={handleStartOver}
          handleRemoveDesign={handleRemoveDesign}
          handleRemoveProduct={handleRemoveProduct}
          handleSubmitQuoteRequest={handleSubmitQuoteRequest}
          handleChange={handleChange}
          formData={formData}
          recaptchaKey={props.recaptchaKey}
          modalStepNumber={modalStepNumber}
          setModalStepNumber={setModalStepNumber}
        />
      )}
      {desktopWizardButtonDomNode &&
        createPortal(
          openModalButton({
            isNew: !designRequestInProgress,
            onClick: handleOpenModal,
          }),
          desktopWizardButtonDomNode
        )}
      {mobileWizardButtonDomNode &&
        createPortal(
          openModalButton({
            isNew: !designRequestInProgress,
            onClick: handleOpenModal,
          }),
          mobileWizardButtonDomNode
        )}
      {quotePageButtonDomNode &&
        createPortal(
          openModalButton({
            isNew: !designRequestInProgress,
            onClick: handleOpenModal,
          }),
          quotePageButtonDomNode
        )}
      {addDesignDomNode &&
        createPortal(
          <AddDesignButton
            updateDesign={updateDesign}
            buttonText={addDesignButtonText}
          />,
          addDesignDomNode
        )}
      {addProductDomNode &&
        createPortal(
          <AddProductButton
            updateProduct={updateProduct}
            buttonText={addProductButtonText}
          />,
          addProductDomNode
        )}
      <Drawer
        designRequestInProgress={designRequestInProgress}
        handleOpenModal={handleOpenModal}
        productColor={formData.productColor}
        designGalleryUrl={props.designGalleryUrl}
        productGalleryUrl={props.productGalleryUrl}
        formData={formData}
      />
    </>
  );
};

export default OrderWizard;
