import React from 'react';
import NukaCarousel from 'nuka-carousel';
import Star from '../images/redesign-images/about-us/star.svg';

export default function TestimonialCarousel() {
  let nukaCarouselProps = {
    slidesToShow: 1,
    autoplay: true,
    wrapAround: true,
    withoutControls: true,
    autoplayInterval: 4000,
    speed: 400,
    easing: 'easeQuadInOut',
    cellSpacing: 0,
  };

  return (
    <div>
      <h2 className="c-heading-2 heading-liberator heading-liberator-light white">
        Testimonials + Reviews
      </h2>
      <div className="stack stack--large">
        <div className="row row-centered2 gap-m">
          <img src={Star} alt="" className="star-res" />
          <img src={Star} alt="" className="star-res" />
          <img src={Star} alt="" className="star-res" />
          <img src={Star} alt="" className="star-res" />
          <img src={Star} alt="" className="star-res" />
        </div>
        <div className="white phxxl phn--m">
          <NukaCarousel {...nukaCarouselProps}>
            <div className="c-heading-2 white heading-bold">
              Worked with me every step of the way to make sure I got the best
              possible shirts for my sorority. - Hannah
            </div>
            <div className="c-heading-2 white heading-bold">
              Good quality, great customer service and very fast delivery. - Tom
              S.
            </div>
            <div className="c-heading-2 white heading-bold">
              University Tees has been instrumental in the launch of our
              lifestyle apparel brand. - Cora W.
            </div>
            <div className="c-heading-2 white heading-bold">
              The shirts are incredible. They look amazing. We are so pleased
              with how they turned out! - Peyton T.
            </div>

            <div className="c-heading-2 white heading-bold">
              Great speed of service, excellent campus representative, excellent
              product. - Brendan
            </div>
            <div className="c-heading-2 white heading-bold">
              It was so easy to work with UTees and Jen Monter! I like how the
              order progression is monitored and you're alerted via email each
              step of the way. Quick, easy, and organized! - Amy
            </div>
            <div className="c-heading-2 white heading-bold">
              Customer service is the best around!!!! Great products, but what
              really makes them stand out to me is their customer service…they
              go above & beyond to make sure customers are happy!!!! I
              definitely recommend!!!! - Kelly
            </div>
            <div className="c-heading-2 white heading-bold">
              The campus rep did an absolutely wonderful job and my chapter is
              beyond happy with the quality of the order! - Ana
            </div>
          </NukaCarousel>
        </div>
      </div>
    </div>
  );
}
