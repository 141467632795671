import React from 'react';
import logoShop from '../../images/logo-utees-shop.svg';

const Step5 = ({ toggleClick }) => {
  return (
    <section className="stack stack--large centered stretch phm" id="step-4">
      <div className="live-quote__header capped row row-end mbl">
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Thank you, you're in!
        </h1>
        <div className="stack stack--small">
          <p className="phs">
            <small>
              Your Campus Manager will be in touch to get started on an order
              and get you closer to rewards.
            </small>
          </p>
          <p className="phs">
            <small>
              Interested in getting in touch now? Contact your{' '}
              <a href="/campus-managers">Campus Manager</a>.
            </small>
          </p>
        </div>
        <div className="capped-narrow grid-2_1">
          <div>
            <a className="button button--full stretch" href="/designs">
              Browse Design Gallery
            </a>
          </div>
          <div>
            <a
              className="button button--full stretch"
              href="/products?product_filter%5Bproduct_collection_ids%5D%5B%5D=29#products"
            >
              View Exclusive Products
            </a>
          </div>
          <div>
            <a
              className="button button--full stretch"
              href="https://www.blog.universitytees.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Explore the blog
            </a>
          </div>
          <div>
            <a
              className="button button--full stretch"
              href="https://www.pinterest.com/universitytees"
              target="_blank"
              rel="noreferrer noopener"
            >
              Get ideas on Pinterest
            </a>
          </div>
        </div>

        <div className="capped row row-centered2">
          <div className="col-6--m">
            <p className="phs">
              <small>
                Shop single-piece apparel for your organization on the
              </small>
            </p>
            <h2>
              <img src={logoShop} alt="Logo for the University Tees Shop" />
              <span className="is-hidden-visually">University Tees Shop</span>
            </h2>
            <a
              href="https://shop.universitytees.com"
              className="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shop Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step5;
