// Import CSS
import '../stylesheets/application.scss';

// Just standard module imports
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Rails from '@rails/ujs';
import customPolyfills from '../custom-polyfills';
import DOMFiddling from '../dom-fiddling';
import ScrollNavigation from '../ScrollNavigation';
import initSupport from '../support';
import reCAPTCHA from '../reCAPTCHA';
import firstVisit from '../firstVisit';

// Import images
const importAll = r => r.keys().map(r);
importAll(require.context('../images', true, /\.(gif|png|jpe?g|svg)$/));

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

Rails.start();

const App = () => ReactRailsUJS.useContext(componentRequireContext);
App();

customPolyfills();
initSupport();
DOMFiddling();
ScrollNavigation();
reCAPTCHA();
firstVisit();
