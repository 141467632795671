import React from 'react';

const Step1 = ({
  value,
  onChange,
  onSubmit,
  backButton,
  toggleClick,
  organizations,
  schools,
}) => {
  return (
    <section className="stack stack--large stretch phm" id="step-2">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 1 of 4</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Join UTees VIP Rewards Below
        </h1>
      </div>

      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="grid-2_1">
          <div className="stack stack--small">
            <label className="input-label" htmlFor="first-name">
              First Name*
            </label>
            <input
              className="field"
              type="text"
              id="first-name"
              value={value.firstName}
              required={true}
              placeholder="Ex: Johnny"
              onChange={e => {
                onChange({ ...value, firstName: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="last-name">
              Last Name*
            </label>
            <input
              className="field"
              type="text"
              id="last-name"
              value={value.lastName}
              required={true}
              placeholder="Ex: Appleseed"
              onChange={e => {
                onChange({ ...value, lastName: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="email">
              Email*
            </label>
            <input
              className="field"
              type="email"
              id="email"
              required={true}
              placeholder="Ex: email@address.com"
              value={value.email}
              onChange={e => {
                onChange({ ...value, email: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="phone">
              Phone Number*
            </label>
            <input
              className="field"
              type="tel"
              id="phone"
              required={true}
              value={value.phone}
              placeholder="Ex: 555-555-5555"
              onChange={e => {
                onChange({ ...value, phone: e.target.value });
              }}
            />
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="school">
              School*
            </label>
            <select
              className="field"
              type="text"
              id="school"
              required={true}
              value={value.school}
              onChange={e => {
                onChange({ ...value, school: e.target.value });
              }}
              onBlur={e => {
                onChange({ ...value, school: e.target.value });
              }}
            >
              {schools.map(school => (
                <option key={school} value={school}>
                  {school}
                </option>
              ))}
            </select>
          </div>

          <div className="stack stack--small">
            <label className="input-label" htmlFor="organization">
              Organization*
            </label>
            <select
              className="field"
              type="text"
              id="organization"
              required={true}
              value={value.organization}
              onChange={e => {
                console.log('change');
                onChange({ ...value, organization: e.target.value });
              }}
              onBlur={e => {
                console.log('blur');
                onChange({ ...value, organization: e.target.value });
              }}
            >
              {organizations.map(org => (
                <option key={org} value={org}>
                  {org}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="centered mtl">
          <button className="button">Next Step</button>
        </div>
      </form>
    </section>
  );
};

export default Step1;
