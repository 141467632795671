import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IconCopy from '../images/icons/icon-copy.svg';

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f

const supportsCopy = document.queryCommandSupported('copy');

function CopyCode({ code }) {
  const inputRef = useRef();

  function handleClick() {
    const el = inputRef.current;
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  return (
    <div className="copy-link">
      <label htmlFor="copy-code" className="is-hidden-visually">
        Copy the link
      </label>
      <input
        id="copy-code"
        readOnly
        value={code}
        className="code"
        type="text"
        ref={inputRef}
      />

      {supportsCopy && (
        <button
          className="button-copy"
          onClick={handleClick}
          type="button"
          title="Copy to clipboard"
        >
          <img src={IconCopy} alt="Copy the link" />
        </button>
      )}
    </div>
  );
}

CopyCode.propTypes = {
  code: PropTypes.string.isRequired,
};

export default CopyCode;
