import React from 'react';
import Modal from './Modal';
import backgroundImage from '../images/photography/modal-bg.jpg';

export default function SignupModal() {
  const leftPaneProps = {
    className: 'col-6--l pvl col-hidden--l mbn',
    style: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: ['top', 'center'],
      backgroundSize: 'cover',
    },
  };

  const rightPaneProps = {
    className: 'col-6--l mbn pvl row row-centered row-centered2',
  };

  const modalProps = {
    modalOpen: true,
    modalMaxHeight: 'none',
    modalMaxWidth: '900px',
  };

  return (
    <Modal {...modalProps}>
      <div className="row row-full-height">
        <div {...leftPaneProps} />
        <div {...rightPaneProps}>
          <div className="stack phl">
            <h3 className="heading-base c-heading-1 heading-liberator-light">
              Keep in Touch!
            </h3>
            <p>
              Join our email list for 10% off our online retail store,{' '}
              <a
                href="https://shop.universitytees.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop UTees
              </a>
            </p>
            <div className="clearfix">
              <div className="klaviyo-form-SD2FJx"></div>
            </div>
            <p className="x-small pts">
              By signing up, you agree to receive marketing text messages from
              University Tees at the number provided, including messages sent by
              autodialer. Consent is not a condition of any purchase. Message
              and data rates may apply. Message frequency varies. Reply HELP for
              help or STOP to cancel. View our{' '}
              <a href="/privacy_policy">Privacy Policy</a> and{' '}
              <a href="/terms_of_use">Terms of Use</a>.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
