import React, { useState } from 'react';

function Drawer(props) {
  const [drawerOpen, setDrawerOpen] = useState(
    props.formData.design || props.formData.product
  );

  function handleCloseDrawer() {
    setDrawerOpen(false);
  }

  const wizardClosedDrawerUI = (
    <div className="wizard-drawer-closed">
      <button
        className="button button-pseudo drawer-open-button"
        onClick={() => setDrawerOpen(true)}
      >
        +
      </button>
      {props.formData.product && !props.formData.design && (
        <img
          src={props.productColor.marketingImageUrl}
          alt="Product Preview"
          className="drawer-design"
        />
      )}
      {props.formData.design && (
        <img
          src={props.formData.design.url}
          alt="Design Square"
          className="drawer-design"
        />
      )}
    </div>
  );

  const selectedDesignUI = props.formData.design && (
    <div
      className="drawer-square pointer"
      onClick={props.handleOpenModal}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.handleOpenModal();
        }
      }}
      role="button"
      tabIndex="0"
    >
      <img src={props.formData.design.url} alt="Design Square" />
    </div>
  );

  const placeholderDesignUI = (
    <div>
      <a href={props.designGalleryUrl}>
        <div className="drawer-square centered uppercase pas">
          <p>Add Design</p>
        </div>
      </a>
    </div>
  );

  const designSection = props.formData.design
    ? selectedDesignUI
    : placeholderDesignUI;

  const selectedProductUI = props.formData.product && (
    <div
      className="drawer-square pointer"
      onClick={props.handleOpenModal}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.handleOpenModal();
        }
      }}
      role="button"
      tabIndex="0"
    >
      <img
        src={props.productColor.marketingImageUrl}
        alt={props.productColor.name}
      />
    </div>
  );

  const placeholderProductUI = (
    <div>
      <a href={props.productGalleryUrl}>
        <div className="drawer-square centered uppercase pas">
          <p>Add Product</p>
        </div>
      </a>
    </div>
  );

  const productSection = props.formData.product
    ? selectedProductUI
    : placeholderProductUI;

  const wizardDrawerUI = (
    <div className="wizard-drawer pam">
      <button
        className="button button-pseudo drawer-minimize-button"
        onClick={handleCloseDrawer}
      >
        -
      </button>
      {designSection}
      {productSection}
    </div>
  );

  return (
    <div>
      {drawerOpen && wizardDrawerUI}
      {props.designRequestInProgress && !drawerOpen && wizardClosedDrawerUI}
    </div>
  );
}

export default Drawer;
