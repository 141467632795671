import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { clickEvent } from '../utility';

const events = ['play', 'pause'];

const clickEventName = clickEvent();

function VideoPromo(props) {
  const [isPaused, setIsPaused] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);

  const wipeHover = useMemo(
    () => debounce(() => setIsHovered(false), 2000),
    []
  );

  useEffect(() => {
    const syncState = () => {
      if (ref === null) return;
      setIsPaused(ref.current.paused);
      setIsHovered(old => (isPaused ? old : false));
    };

    if (ref === null) return;
    const currentVideoRef = ref.current;

    for (let event of events) {
      currentVideoRef.addEventListener(event, syncState);
    }

    wipeHover.cancel();

    return () => {
      if (currentVideoRef === null) return;
      for (let event of events) {
        currentVideoRef.removeEventListener(event, syncState);
      }
    };
  }, [isPaused, wipeHover]);

  function play() {
    if (ref === null) return;
    ref.current.play();
  }

  function pause() {
    if (ref === null) return;
    ref.current.pause();
  }

  function handleHover(isHovered) {
    setIsHovered(isHovered);
    wipeHover();
  }

  const toggle = () => {
    if (isPaused) {
      play();
    } else {
      pause();
    }
  };

  let { ...vidProps } = props;

  const wrapperClass = classNames({
    'video-wrapper': true,
    'video-paused': isPaused,
    'video-playing': !isPaused,
    'video-hovering': isHovered,
  });

  let videoProps = {
    className: `fullscreen${props.customclass ? ` ${props.customclass}` : ''}`,
    ref,
    [clickEventName]: toggle,
    ...vidProps,
  };

  let wrapperProps = {
    className: wrapperClass,
    onMouseEnter: () => handleHover(true),
    onMouseMove: () => handleHover(true),
    onMouseLeave: () => handleHover(false),
  };

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div {...wrapperProps}>
      <video {...videoProps} />
    </div>
  );
  /* eslint-enable jsx-a11y/media-has-caption */
}

export default VideoPromo;
