import React from 'react';

const Step3 = ({ value, onChange, onSubmit, backButton, toggleClick }) => {
  return (
    <section className="stack stack--large stretch phm" id="step-3">
      <div className="live-quote__header capped row row-pushed mbl">
        <button onClick={backButton} className="live-quote__back">
          <span className="is-hidden-visually">Back</span>
        </button>
        <div>
          <strong>Step 3 of 4</strong>
        </div>
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Recruitment Chair Info
        </h1>
      </div>
      <form
        className="live-quote-animated capped-narrow stack"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="stack stack--small">
          <label className="input-label" htmlFor="recruitment-chair-first-name">
            Recruitment Chair First Name
          </label>
          <input
            className="field"
            type="text"
            id="recruitment-chair-first-name"
            value={value.recruitmentChairFirstName}
            placeholder="Ex: Johnny"
            onChange={e => {
              onChange({ ...value, recruitmentChairFirstName: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="recruitment-chair-last-name">
            Recruitment Chair Last Name
          </label>
          <input
            className="field"
            type="text"
            id="recruitment-chair-last-name"
            value={value.recruitmentChairLastName}
            placeholder="Ex: Appleseed"
            onChange={e => {
              onChange({ ...value, recruitmentChairLastName: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="recruitment-chair-email">
            Recruitment Chair Email
          </label>
          <input
            className="field"
            type="email"
            id="recruitment-chair-email"
            placeholder="Ex: email@address.com"
            value={value.recruitmentChairEmail}
            onChange={e => {
              onChange({ ...value, recruitmentChairEmail: e.target.value });
            }}
          />
        </div>

        <div className="stack stack--small">
          <label className="input-label" htmlFor="recruitment-chair-phone">
            Recruitment Chair Phone Number
          </label>
          <input
            className="field"
            type="tel"
            id="recruitment-chair-phone"
            value={value.recruitmentChairPhone}
            placeholder="Ex: 555-555-5555"
            onChange={e => {
              onChange({ ...value, recruitmentChairPhone: e.target.value });
            }}
          />
        </div>

        <div className="centered mtl">
          <button className="button">One More Step</button>
        </div>
      </form>
    </section>
  );
};

export default Step3;
