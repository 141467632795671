import React, { useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { isServerSideRendering } from '../utility';
import ColorCircle from './ColorCircle';

function Chips({ children }) {
  if (isServerSideRendering()) {
    return children;
  } else {
    // NOTE: don't move this getElementById outside of this conditional. It will break SSR!
    const portalNode = document.getElementById('product-detail-chips');
    return ReactDOM.createPortal(children, portalNode);
  }
}

export default function ProductImageGallery({ featuredImages, colors }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const defaultImage = featuredImages[0];

  const imageToShow = () => {
    if (selectedImage !== null) {
      return selectedImage;
    } else if (selectedColor !== null) {
      return colors.find(({ id }) => id === selectedColor).marketingImageUrl;
    } else {
      return defaultImage;
    }
  };

  const onSelectChip = color => () => {
    setSelectedColor(color.id);
    setSelectedImage(null);
  };

  // If a user selects a product color, we add the product color data to the dataset. If they choose simply a product, we add the default product image.
  const selectedColorObject = useMemo(() => {
    let newSelectedColorObject = { marketingImageUrl: defaultImage };
    if (selectedColor) {
      newSelectedColorObject = {
        ...newSelectedColorObject,
        ...colors.find(({ id }) => id === selectedColor),
      };
    }

    return newSelectedColorObject;
  }, [selectedColor, colors, defaultImage]);

  useEffect(() => {
    const addToOrderButton = document.getElementById('add-product-button');
    addToOrderButton.dataset.productColor = JSON.stringify(selectedColorObject);
  }, [selectedColorObject]);

  return (
    <div>
      <div className="image-container">
        <div className="image-container__thumbs">
          {featuredImages.map((url, i) => (
            <div
              key={i}
              role="button"
              tabIndex={0}
              onClick={() => {
                setSelectedImage(url);
                setSelectedColor(null);
              }}
              onKeyDown={e => {
                if (e.code === 'Space') {
                  setSelectedImage(url);
                  setSelectedColor(null);
                  e.preventDefault();
                }
              }}
            >
              <img
                src={url}
                alt="Change product view"
                style={{ cursor: 'pointer' }}
                className="image-thumbnails u-border-radius-sm"
              />
            </div>
          ))}
        </div>
        <div>
          <img
            className="primary-image u-border-radius-md"
            src={imageToShow()}
            alt=""
          />
        </div>
      </div>

      {colors.length > 0 && (
        <Chips>
          <div className="chips mbm">
            {colors.map(color => {
              const selectColor = onSelectChip(color);
              const colorProps = {
                tabIndex: 0,
                onClick: selectColor,
                'aria-label': color.name,
                role: 'button',
                onKeyDown: e => {
                  if (e.code === 'Space') {
                    selectColor();
                    e.preventDefault();
                  }
                },
              };

              return (
                <div className="tooltip-item" key={color.id}>
                  <ColorCircle
                    color={color.hex_code}
                    style={{
                      cursor: 'pointer',
                    }}
                    {...colorProps}
                  >
                    <div className="tooltip">
                      <p>{color.name}</p>
                    </div>
                  </ColorCircle>
                </div>
              );
            })}
          </div>
        </Chips>
      )}
    </div>
  );
}
