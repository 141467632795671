import { createElement } from 'react';

export const isString = value =>
  typeof value === 'string' || value instanceof String;

export const hydrateReactElements = elements => {
  return elements === null
    ? null
    : elements.map((row, key) => {
        if (row === null || isString(row)) {
          return row;
        } else {
          let [el, props, children] = row;
          return createElement(
            el,
            { ...props, key },
            hydrateReactElements(children)
          );
        }
      });
};

export const getAuthenticityToken = () => {
  let meta = document.getElementsByTagName('meta');
  for (let i = 0; i < meta.length; i++) {
    if (meta[i].getAttribute('name') === 'csrf-token') {
      return meta[i].getAttribute('content');
    }
  }
};

// https://stackoverflow.com/a/9039885/456337
// TODO: Ask about Zendesk iOS bug that required this workaround
export const iOS = () => {
  const iOSDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ];

  if (navigator.platform) {
    while (iOSDevices.length) {
      if (navigator.platform === iOSDevices.pop()) {
        return true;
      }
    }
  }

  return false;
};

export const viewPortWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth;
};

export const offset = el => {
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };
};

export const easingLinear = function(n, m, x) {
  return -1 * (n / m) * x + n;
};

export const easingQuadraticIn = function(n, m, x) {
  return -1 * n * Math.pow(x / m, 2) + n;
};

export const easingQuadraticOut = function(n, m, x) {
  const yb = n * (x / m) * (x / m - 2) + n;
  const yf = -n * (x / m) * (x / m - 2) - n;
  return x > m ? yf : yb;
};

export const makeCurveFunction = function(n, m, f) {
  return f.bind(undefined, n, m);
};

export const clickEvent = () => {
  if ('ontouchstart' in document.documentElement === true) return 'onTouchEnd';
  else return 'onClick';
};

export const isServerSideRendering = () => typeof window === 'undefined';
