import React from 'react';
import Fraternity from '../../images/redesign-images/rewards/loyalty-fraternity-path.svg';
import LargeSorority from '../../images/redesign-images/rewards/loyalty-large-sorority-path.svg';
import SmallSorority from '../../images/redesign-images/rewards/loyalty-small-sorority-path.svg';

const Step0 = ({ onChange, toggleClick }) => {
  return (
    <section className="stack stack--large phm" id="step-0">
      <div className="live-quote__header capped row row-end">
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="heading-base heading-large heading-heavy heading-liberator">
          Join UTees VIP Rewards Below
        </h1>
        <p className="medium medium--res">
          <em>Which of the following best describes your group?</em>
        </p>
      </div>

      <div className="capped-medium row">
        <div className="col-4--s phm pvs">
          <button
            className="vip-promo vip-promo-bg vip-promo-bg--alt vip-promo__button paxl pal--m stack"
            type="button"
            onClick={() => onChange('Small Sororities')}
          >
            <p className="heading-base uppercase dark-gray">Path #1</p>
            <img src={SmallSorority} alt="Sororities less than 200 members" />
          </button>
        </div>
        <div className="col-4--s phm pvs">
          <button
            className="vip-promo vip-promo-bg vip-promo-bg--alt vip-promo__button paxl pal--m stack"
            type="button"
            onClick={() => onChange('Large Sororities')}
          >
            <p className="heading-base uppercase dark-gray">Path #2</p>
            <img src={LargeSorority} alt="Sororities over 200 members" />
          </button>
        </div>
        <div className="col-4--s phm pvs">
          <button
            className="vip-promo vip-promo-bg vip-promo-bg--alt vip-promo__button paxl pal--m stack"
            type="button"
            onClick={() => onChange('Fraternities')}
          >
            <p className="heading-base uppercase dark-gray">Path #3</p>
            <img src={Fraternity} alt="Fraternities" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Step0;
