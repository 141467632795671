import React from 'react';
import NukaCarousel from 'nuka-carousel';
import InstagramIcon from '../images/icons/icon-instagram.svg';
import TikTokIcon from '../images/icons/icon-tiktok.svg';
import TwitterIcon from '../images/icons/icon-twitter.svg';
import TeeIcon from '../images/icons/icon-tee.svg';
import JoeySwoll from '../images/redesign-images/collabs/influencers/influencer-joey-swoll.jpg';
import Hamptons from '../images/redesign-images/collabs/influencers/influencer-hamptons.jpg';

const SingleCarousel = () => {
  let nukaCarouselProps = {
    slidesToShow: 1,
    autoplay: true,
    wrapAround: true,
    withoutControls: true,
    autoplayInterval: 4000,
    speed: 400,
    easing: 'easeQuadInOut',
    cellSpacing: 0,
  };

  return (
    <NukaCarousel {...nukaCarouselProps}>
      <div>
        <figure className="figure-overlay man stretch">
          <img
            src={JoeySwoll}
            className="stretch"
            alt=""
            height="866"
            width="693"
          />
          <figcaption className="figure-overlay__content centered stack">
            <span>
              Joey Swoll, @joeyswoll, is using merch to spread his mission of
              gym positivity one shirt at a time.
            </span>
            <div className="row row-centered2 row-gap-large">
              <div className="stack stack--small">
                <img
                  src={InstagramIcon}
                  width="50"
                  height="50"
                  className="block centered"
                  alt="Instagram logo"
                />
                <p>2.2M</p>
              </div>
              <div className="stack stack--small">
                <img
                  src={TikTokIcon}
                  width="50"
                  height="50"
                  className="block centered"
                  alt="TikTok logo"
                />
                <p>5.5M</p>
              </div>
              <div className="stack stack--small">
                <img
                  src={TwitterIcon}
                  width="50"
                  height="50"
                  className="block centered"
                  alt="Twitter logo"
                />
                <p>100K</p>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
      <div>
        <figure className="figure-overlay man stretch">
          <img
            src={Hamptons}
            className="stretch"
            alt=""
            height="866"
            width="693"
          />
          <figcaption className="figure-overlay__content centered stack">
            <span>
              Sam Wiggins with @heckstudios is re-inventing bachelorette party
              apparel through her unique custom designs.
            </span>
            <div className="row row-centered2 row-gap-large">
              <div className="stack stack--small centered">
                <img
                  src={InstagramIcon}
                  width="50"
                  height="50"
                  className="block centered2"
                  alt="Instagram logo"
                />
                <p>4K</p>
              </div>
              <div className="stack stack--small centered">
                <img
                  src={TikTokIcon}
                  width="50"
                  height="50"
                  className="block centered2"
                  alt="TikTok logo"
                />
                <p>84.5K</p>
              </div>
              <div className="stack stack--small centered">
                <img
                  src={TeeIcon}
                  width="50"
                  height="50"
                  className="block centered2"
                  alt="T-shirt"
                />
                <p>150+</p>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </NukaCarousel>
  );
};

export default SingleCarousel;
