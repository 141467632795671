import React from 'react';

function ModalPageTwo(props) {
  const handleSubmit = e => {
    e.preventDefault();
    props.handleSubmitQuoteRequest();
  };

  const handleBack = e => {
    e.preventDefault();
    props.handleBack();
  };

  const pageTwoHeader = (
    <div>
      <p className="uppercase centered mtn">2 of 2</p>
      <h2 className="heading-liberator-light c-heading c-heading-2 centered mvn">
        Give us the deets
      </h2>
      <p className="mts mbl centered small">
        Once you submit your quote request, we will contact you shortly.
      </p>
    </div>
  );

  const studentCheckboxUI = (
    <div className="col-4--m row row-gap-small mbm">
      <input
        type="checkbox"
        id="student-checkbox"
        checked={!props.formData.isStudent || false}
        onChange={e => {
          props.handleChange('isStudent', !e.target.checked);
        }}
      />
      <label htmlFor="student-checkbox" className="strong">
        Not a student?
      </label>
    </div>
  );

  function formatPhoneNumber(input) {
    // Remove all non-numeric characters
    input = input.replace(/\D/g, '');

    // Format the input as xxx-xxx-xxxx
    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
    } else if (input.length > 12) {
      input = `${input.slice(0, 12)}` - ' ';
    }

    // Limit the length to 12 characters (including hyphens)
    return input;
  }

  const phoneFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="phone">
        Phone Number*
      </label>
      <input
        className="field"
        type="tel"
        id="phone"
        required={true}
        placeholder="Ex: 555-555-5555"
        value={props.formData.phoneNumber || ''}
        onChange={e => {
          const formattedPhoneNumber = formatPhoneNumber(e.target.value);
          props.handleChange('phoneNumber', formattedPhoneNumber);
        }}
      />
    </div>
  );

  const schoolFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="school">
        School*
      </label>
      <select
        className="field"
        type="text"
        id="school"
        required={true}
        value={props.formData.school}
        onChange={e => {
          props.handleChange('school', e.target.value);
        }}
        onBlur={e => {
          props.handleChange('school', e.target.value);
        }}
      >
        {props.schools.map(school => (
          <option key={school} value={school}>
            {school}
          </option>
        ))}
      </select>
    </div>
  );

  const organizationFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="organization">
        Organization
      </label>
      <input
        className="field"
        type="text"
        id="organization"
        value={props.formData.organization || ''}
        placeholder="Ex: Chi Omega, Nursing"
        onChange={e => {
          props.handleChange('organization', e.target.value);
        }}
      />
    </div>
  );

  const quantityFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="quantity">
        Estimated Quantity
      </label>
      <input
        className="field"
        type="text"
        id="quantity"
        value={props.formData.quantity || ''}
        placeholder="Ex: 100"
        onChange={e => {
          props.handleChange('quantity', e.target.value);
        }}
      />
    </div>
  );

  const deliveryDateUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="delivery-date">
        Delivery Date*
      </label>
      <input
        className="field field--date"
        type="date"
        id="delivery-date"
        placeholder="MM/DD/YYYY"
        value={props.formData.deliveryDate || ''}
        required={true}
        onChange={e => {
          props.handleChange('deliveryDate', e.target.value);
        }}
      />
    </div>
  );

  const budgetFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="budget">
        Budget
      </label>
      <input
        className="field"
        type="text"
        id="budget"
        value={props.formData.budget || ''}
        placeholder="Ex: $1250"
        onChange={e => {
          props.handleChange('budget', e.target.value);
        }}
      />
    </div>
  );

  const businessNameFieldUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="business">
        Business / Organization*
      </label>
      <input
        className="field"
        type="text"
        id="business"
        required={true}
        value={props.formData.businessName || ''}
        placeholder="Ex: Apple, Inc."
        onChange={e => {
          props.handleChange('businessName', e.target.value);
        }}
      />
    </div>
  );

  const eventName = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="eventName">
        Event Name
      </label>
      <input
        className="field"
        id="eventName"
        value={props.formData.eventName || ''}
        placeholder="Ex. Bid Day"
        onChange={e => {
          props.handleChange('eventName', e.target.value);
        }}
      />
    </div>
  );

  const nameUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="name">
        Name*
      </label>
      <input
        className="field"
        type="text"
        id="name"
        value={props.formData.name || ''}
        required={true}
        placeholder="Ex: Johnny Appleseed"
        onChange={e => {
          props.handleChange('name', e.target.value);
        }}
      />
    </div>
  );

  const emailUI = (
    <div className="stack stack--small">
      <label className="input-label-thin" htmlFor="email">
        Email*
      </label>
      <input
        className="field"
        type="email"
        id="email"
        required={true}
        placeholder="Ex: email@address.com"
        value={props.formData.email || ''}
        onChange={e => {
          props.handleChange('email', e.target.value);
        }}
      />
    </div>
  );

  const buttonUI = (
    <div className="wizard-button-wrapper">
      <button
        className="button button--alt button--full-res mtm"
        onClick={handleBack}
      >
        Back
      </button>
      <button className="button button--full-res mtm">
        Submit Quote Request Now
      </button>
    </div>
  );

  const orderNotesUI = (
    <div className="mtn">
      <label className="input-label-thin mtl" htmlFor="orderNotes">
        Anything else?
      </label>
      <textarea
        id="orderNotes"
        value={props.formData.orderNotes}
        className="field"
        onChange={e => {
          props.handleChange('orderNotes', e.target.value);
        }}
        placeholder="Provide any additional information that will help with your order request."
      />
    </div>
  );

  const salesStreamSpecificQuoteFields = props.formData.isStudent ? (
    <>
      {schoolFieldUI}
      {organizationFieldUI}
      {eventName}
    </>
  ) : (
    <>{businessNameFieldUI}</>
  );

  const quoteFormUI = (
    <form onSubmit={handleSubmit} id="quote-request-details">
      <div className="grid-2_1">
        {nameUI}
        {emailUI}
        {phoneFieldUI}
        {deliveryDateUI}
        {budgetFieldUI}
        {quantityFieldUI}
      </div>
      <hr></hr>
      {studentCheckboxUI}
      <div className="grid-2_1">{salesStreamSpecificQuoteFields}</div>
      {orderNotesUI}
      {buttonUI}
    </form>
  );

  return (
    <div>
      {pageTwoHeader}
      {quoteFormUI}
    </div>
  );
}
export default ModalPageTwo;
