// https://stackoverflow.com/a/5624139/456337
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const hexNormalized = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  const result = regex.exec(hexNormalized);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

// https://stackoverflow.com/a/9733420/456337
export const luminanace = (r, g, b) => {
  const a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

// https://stackoverflow.com/a/9733420/456337
export const contrast = (rgb1, rgb2) =>
  (luminanace(rgb1.r, rgb1.g, rgb1.b) + 0.05) /
  (luminanace(rgb2.r, rgb2.g, rgb2.b) + 0.05);
