export default () => {
  const forms = document.querySelectorAll('[data-recaptcha]');

  forms.forEach(form => {
    const button = form.querySelector("[type='submit']");
    const grecaptcha = window.grecaptcha;

    button.onclick = e => {
      e.preventDefault();

      grecaptcha.ready(() => {
        grecaptcha
          .execute(window.googleRecaptchaSiteKey, {
            action: form.getAttribute('data-recaptcha'),
          })
          .then(token => {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'g-recaptcha-response');
            input.setAttribute('value', token);
            form.appendChild(input);

            form.submit();
          });
      });
    };
  });
};
